<template>
  <div class="reserve-contain">
    <div class="reser-image">
      <img :src="reservation.photos[0]" alt="">
    </div>
    <div class="reser-txt">
      <div class="txt-contain">
        <span class="reser-logement">
          {{ reservation.annonce.titreAnnonce }}
        </span>
        <hr>
        <span class="reser-date">
          {{ dateCreation }}
        </span>
        <!-- <span class="reser-price">
          250.000 fcfa
        </span> -->
        <span class="reser-author">
          Par <b>{{ reservation.nom + " " + reservation.prenom }}</b>
        </span>
        <span class="reser-author">
          Tel: <b>{{ reservation.tel }}</b>
        </span>
        <span class="reser-author">
          E-mail: <b>{{ reservation.email }}</b>
        </span>
      </div><br>
      <div class="btns">
        <button  v-if="reservation.status == 'En cours'" @click="retenir()">Retenir</button>
        <button @click="showDetails()">
          <i class="ik ik-eye"></i>
          <span>Détails</span>
        </button>
        <button v-if="reservation.status == 'En cours' || reservation.status == 'En attente'" @click="rejeter()">
          <i class="ik ik-trash-2"></i>
          <span>Refuser</span>
        </button>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="isModalVisible" class="modal-overlay">
      <div id="modal">
        <div class="modal-header">
          <h3>Détails de la réservation</h3>
          <button @click="closeModal" id="closeModal">X</button>
        </div>
        <div class="modal-body">
          <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
            <div>
              <img :src=reservation.avatar alt="Avatar" style="height: 150px;width: 150px;border-radius: 6px;">
            </div>
            <div style="display: flex;flex-direction: column;justify-content: center;width: 60%;">
              <p><b>Nom:</b> {{ reservation.nom }} {{ reservation.prenom }}</p>
              <p><b>Email:</b> {{ reservation.email }}</p>
              <p><b>Téléphone:</b> {{ reservation.tel }}</p>
            </div>
          </div>
          <p><b>Date de réservation:</b> {{ dateCreationModal }}</p>
          <p v-if="reservation.aPropose"><b>Proposition faite :</b></p>
          <p v-if="reservation.aPropose" style="padding-left: 20px;">
            <b>Loyer mensuelle :</b> {{ priceFormat(reservation.loyerPropose) }} XAF<br>
            <b>Nombre de mois à avancer :</b> {{ reservation.nombreDeMois }} mois
          </p>
          <p v-if="reservation.message">
           <b>Message:</b> <br>{{ reservation.message }}
          </p>
          <p style="background-color: antiquewhite;">
            <b>Reservation : </b>
            <span>{{ reservation.status }}</span>
          </p>
          <!-- {{ reservation }} -->
        </div>
        
        <div class="modal-footer">
          <div class="modalBtns">
            <button id="holdBtn" v-if="reservation.status == 'En cours'" @click="retenir()"> <i class="ik ik-pause"></i>Retenir</button>
            <button id="aproveBtn"v-if="reservation.status == 'En attente'" @click="approuver()">
              <i class="ik ik-check"></i>
              <span>Aprouver</span>
            </button>
            <button id="rejectBtn" v-if="reservation.status == 'En cours' || reservation.status == 'En attente'" @click="rejeter()">
              <i class="ik ik-trash-2"></i>
              <span>Rejeter</span>
            </button>
            <button id="btnChat" @click="discuterSurWhatsApp(reservation.tel, reservation.nom + ' ' + reservation.prenom, reservation.annonce.titreAnnonce)">
              <i class="mr-2 text-danger fab fa-whatsapp"></i>
              Discuter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isModalVisible2" class="modal-overlay-loading">
      <div id="modal-loading">
        <!-- <div class="modal-body">
          <div style="display: flex;justify-content: center;align-items: center;">
            
        </div> -->
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
const php = require("phpjs");
import { mapGetters } from "vuex";
import { ShareNetwork } from "vue-social-sharing";
export default {
  components: {
    ShareNetwork,
  },
  data: () => ({
    open: true,
    devise:'F',
    isModalVisible: false,
    isModalVisible2: false,
    // dateCreation: '',
  }),
  props: {
    reservation: { type: Object, required: true },
    isSub: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['user', 'hasAccess']),
    dateCreation() {
      const day = this.$date(this.reservation.dateReservation);
      
      return `Reservé :  ${day.format("dddd, DD MMMM YYYY")} à ${day.format(
        "HH:mm"
      )}`;
    },
    dateCreationModal() {
      const day = this.$date(this.reservation.dateReservation);
      
      return `${day.format("dddd, DD MMMM YYYY")} à ${day.format(
        "HH:mm"
      )}`;
    },
    php() {
      return php;
    },
  },
 
  methods: {

    async retenir() {
      this.isModalVisible2 = true;
      axios.put('reservations/'+ this.reservation.id +'/hold')
      .then((response) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        // this.$emit('reloadData');
        setTimeout(() => window.location.reload(), 1000)
        console.log("result ---- ", response.result)
        return App.notifySuccess(response.message);
      })
      .catch((error) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        return App.alertError(error.message);
      });
    },
    async approuver() {
      this.isModalVisible2 = true;
      axios.put('reservations/'+ this.reservation.id +'/approve')
      .then((response) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        // this.$emit('reloadData');
        setTimeout(() => window.location.reload(), 1000)
        console.log("result ---- ", response.result)
        return App.notifySuccess(response.message);
      })
      .catch((error) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        return App.alertError(error.message);
      });
    },
    async rejeter() {
      this.isModalVisible2 = true;
      axios.put('reservations/'+ this.reservation.id +'/reject')
      .then((response) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        // this.$emit('reloadData');
        setTimeout(() => window.location.reload(), 1000)
        console.log("result ---- ", response.result)
        return App.notifySuccess(response.message);
      })
      .catch((error) => {
        this.isModalVisible = false;
        this.isModalVisible2 = false;
        return App.alertError(error.message);
      });
    },
    discuterSurWhatsApp(tel, nom, titreAnnonce) {
      const phoneNumber = '237' + tel; // Remplacez par le numéro de téléphone avec l'indicatif pays
      const message = `
        Bonjour ${nom}, nous espérons que ce message vous trouve bien portant.
        Nous aimerions discuter avec vous au sujet e la reservation que vous avez faite sur l'annonce suivante du site www.maisonier.com :
        ${titreAnnonce}.
      `;
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    },
    priceFormat(number) {
      return String(number).replace(/(.)(?=(\d{3})+$)/g, "$1,");
    },
     showDetails() {
      this.isModalVisible = true; // Affiche la modal
    },
    closeModal() {
      this.isModalVisible = false; // Cache la modal
    },
  },
  mounted() {
    /*if(this.user.preferences.devise==null||this.user.preferences.devise==''){
            this.devise='F'
    }else{
        this.devise= this.user.preferences.devise
    }*/
  //  this.dateCreation= this.dateCreation()
  },
};
</script>

<style scoped>
.max-lines-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.max-lines-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.btn-actions {
  width: 90px;
  margin-top: 10px;
}

.btn-actions i {
  font-size: 18px;
  border-radius: 50%;
  padding: 6px;
}
.btn-actions i:hover {
  background: grey;
}
.btn-actions i:nth-child(1) {
  margin-top: -5px;
}
.closebtn {
  margin-top: -20px;
}

.price {
  font-weight: 600;
  font-size: 16px;
  color: #f5365c;
}
.btn-action:hover {
  color: #f5365c;
}
/* .showModal {
  display: none;
  height: auto;
  width: 13rem;
  border: 1px solid rgba(128, 128, 128, 0.171);
  background: #fff;
  border-radius: 8px;
  position: absolute;
  bottom: 2rem;
  right: -2em;
  padding: 0 10px;
} */

/* .display-menu:hover .showModal {
  display: block !important;
} */

.card {
  border-radius: 8px;
}
.reserve-contain{
  height: 32rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 22px rgba(0, 0, 0, 0.08);
}
.reserve-contain .reser-image{
  position: relative;
  width: 100%;
  height: 45%;
  border-radius: 8px 8px 0 0;
}
.reser-image img{
  width: 100%;
  height:100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
}
.reserve-contain .reser-txt{
  padding: 15px 4%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 55%;
  border-radius: 0 0 8px 8px;
}
.reser-txt .txt-contain{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
}
.reser-txt .reser-logement{
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
}
.reser-txt hr{
  margin: 5px 0 10px;
}
.reser-txt .reser-date{
  width: 100%;
  font-size: .9rem;
  color: #4e4e4e;
}
.reser-txt .reser-author{
  width: 100%;
  margin-top: 6px;
  font-size: .9rem;
  text-align: right;
  color: #4e4e4e;
}
.reser-txt .reser-author b{
  font-weight: 600;
}
.reser-txt .reser-price{
  width: 100%;
  margin-top: 6px;
  font-size: .9rem;
  color: #4e4e4e;
}
.reser-txt .btns{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.btns {
  margin-top: 10%;
}
.btns button {
  background: transparent;
  border: none;
  font-weight: 500;
}
.btns button:nth-child(1){
  width: calc(100% - 55px);
  height: 100%;
  border-radius: 4px;
  background: #f5365c;
  color: white;
  font-size: 1rem;
}
.btns button:nth-child(2), .btns button:nth-child(3){
  width: 50px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #464646;
  color: #464646;
  font-size: 1.1rem;
  transition: .1s;
}
.btns button:nth-child(2) span, .btns button:nth-child(3) span{
  font-size: 0;
  opacity: 0;
  transition: .1s;
}
.btns button:nth-child(2):hover, .btns button:nth-child(3):hover{
  margin-left: 5px;
  width: calc(100% - 55px);
  border: 1px solid #191c22;
  color: #fff;
  background: #191c22;
}
.btns button:nth-child(2):hover + button, .btns button:nth-child(3):hover + button{
  opacity: 0;
}
.btns button:nth-child(2):hover span, .btns button:nth-child(3):hover span{
  margin-left: 5px;
  color: #fff;
  font-size: 1rem;
  opacity: 1;
}


.modal-overlay, .modal-overlay-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-overlay-loading {
  background: rgba(0, 0, 0, 0.075);
  z-index: 205;
}

#modal {
  background: white;
  /* padding: 20px; */
  padding-bottom: 10px;
  border-radius: 8px;
  width: 500px;
  height: 600px;
  z-index: 200;
}
#modal-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  width: 300px;
  height: 300px;
  z-index: 205;
}
#closeModal {
  border: none;
  background-color: transparent;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 20px;
}

/* #closeModal:hover {
  background-color: rgb(255, 0, 0);
} */

/* #closeModal:active {
  transform: scale(0.8);
} */

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  margin-top: 20px;
  height: 400px;
  overflow-y: scroll;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  height: 100px;
}

#holdBtn, #aproveBtn, #rejectBtn {
  border: none;
  font-weight: 600;
  padding: 10px;
  font-size: 15px;
  width: 150px;
  margin-left: 10px;
  border-radius: 6px;
}
#btnChat {
  border: none;
  background-color: #464646;
  color: white;
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  margin-left: 10px;
  border-radius: 6px;
}
#holdBtn:hover, #aproveBtn:hover, #rejectBtn:hover, #btnChat:hover {
  /* background-color: transparent; */
  /* color: black; */
  /* border: 1px solid black; */
  transform: scale(1.1);
}

#holdBtn:active, #aproveBtn:active, #rejectBtn:active, #btnChat:active {
  transform: scaleX(0.8);
}

#holdBtn {
  background-color: rgba(255, 123, 0, 0.966);
  color: white;
}

#aproveBtn {
  background-color: rgb(88, 170, 88);
  color: white;
}

#rejectBtn {
  background-color: rgb(255, 0, 0);
  color: white;
}

.loader {
  border: 6px solid #252525;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.colors2 {
  border-bottom: 16px solid transparent;
}
.colors3 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
}
.colors4 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
  border-left: 16px solid pink;
}

</style>